import { render, staticRenderFns } from "./imageCarousel.vue?vue&type=template&id=01c02055&"
import script from "./imageCarousel.vue?vue&type=script&lang=js&"
export * from "./imageCarousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01c02055')) {
      api.createRecord('01c02055', component.options)
    } else {
      api.reload('01c02055', component.options)
    }
    module.hot.accept("./imageCarousel.vue?vue&type=template&id=01c02055&", function () {
      api.rerender('01c02055', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8jQrit33rNIhD/JS/imageCarousel.vue"
export default component.exports